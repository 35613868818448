module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-autolink-headers","options":{"icon":false}}],"defaultLayouts":{"chapters":"/vercel/path0/src/layouts/chapter.js","default":"/vercel/path0/src/layouts/chapter.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
