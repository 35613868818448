// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-chapter-js": () => import("./../src/layouts/chapter.js" /* webpackChunkName: "component---src-layouts-chapter-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-js": () => import("./../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-en-chapters-js": () => import("./../src/pages/en/chapters.js" /* webpackChunkName: "component---src-pages-en-chapters-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

